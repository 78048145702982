import React, { useState } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { FormRenderProps } from 'react-final-form';
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { object, string, ref } from 'yup';
import axios from 'axios';

import { Layout } from 'components/layouts/login-layout';
import { Field } from 'components/final-form/field';
import { ProgressButton } from 'components/progress-button';
import { PasswordField } from 'components/password-field';
import { Form } from 'components/final-form/form';
import CLIENT_ROUTES from 'constants/endpoints';
import { isBrowser } from 'utilities/gatsby'

const requestPasswordReset =  ({email}: {
  email: string;
}) => {
    return axios.post(`${process.env.GATSBY_API_URL}/auth/forgot-password`,
        {
          email
        }
    )
  }
  
const resetPassword = ({
    code,
    password,
    passwordConfirmation
}: {
  code: string;
  password: string;
  passwordConfirmation: string;
}) => {
    return axios.post(`${process.env.GATSBY_API_URL}/auth/reset-password`,
        {
            code,
            password,
            passwordConfirmation
        }
    );
}

const PasswordSubmissionForm = (props: {
  code: string;
}) => {
  const { t } = useTranslation();
  const [isSubmitSuccessful, setSubmitState] = useState(false);
  const passwordValidationSchema = object().shape({
    password: string()
        .required()
        .min(6)
        .matches(/[0-9]+/, t('validation.require-number')),
    passwordConfirmation: string().oneOf([ref('password'), null], t('validation.password-match-error')),
  })

  if (isSubmitSuccessful) {
    return (
      <Typography align='center'>
        <Trans i18nKey='reset-password.reset-succeeded'>
          Your password has been updated. You can now {<Link to={CLIENT_ROUTES.PARTICIPATE.LOGIN}>log in</Link>}.
        </Trans>
      </Typography>
    )
  }
  else {
    return (
      <Form
        onSubmit={async ({password, passwordConfirmation}) => {
          try {
            await resetPassword({passwordConfirmation, password, code: props.code})
            setSubmitState(true);
          }
          catch (e) {
            return {
              [FORM_ERROR]: t('reset-password.reset-failed')
            };
          }
        }}
        validationSchema={passwordValidationSchema}
        initialValues={{ password: '', passwordConfirmation: ''}}
        render={PasswordSubmissionFormTemplate}
      />
    )
  }
}

const EmailSubmissionForm = () => {
    const { t } = useTranslation();
    const [isSubmitSuccessful, setSubmitState] = useState(false); 
    const emailValidationSchema = object().shape({
        email: string().email().required(t('validation.required')),
    })

    if (isSubmitSuccessful) {
      return (
        <Typography align='center'>
            {t('reset-password.email-confirmation-sent')}
        </Typography>
      )
    } else {
      return (
        <Form
          onSubmit={async (values: {
            email: string;
          }) => {
          try {
            await requestPasswordReset({ email: values.email });
            setSubmitState(true);
          }
          catch (e) {
            return {
              email: t('reset-password.email-error')
            };
          }
          }}
          validationSchema={emailValidationSchema}
          initialValues={{ email: ''}}
          render={EmailSubmissionFormTemplate}
        />
      )
    }
}

interface EmailFormValues {
  email: string;
}

type EmailFormProps = FormRenderProps<EmailFormValues>

const EmailSubmissionFormTemplate = (props: EmailFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, submitting, hasValidationErrors } = props;
  return (
      <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Field
            name='email'
            label={t('register.email')}
            component={TextField}
            type='email'
            disabled={submitting}
            fullWidth autoFocus required
          />
        </Grid>
        <Grid item container justify='center'>
          <ProgressButton
            variant='contained'
            color='primary'
            type='submit'
            progress={submitting}
            disabled={submitting || hasValidationErrors}
          >
            Submit
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  )
}

interface PasswordFormValues {
  password: string;
  passwordConfirmation: string;
}

type PasswordFormProps = FormRenderProps<PasswordFormValues>;

const PasswordSubmissionFormTemplate = (props: PasswordFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, hasValidationErrors, submitting, submitError } = props;
  return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
              name='password'
              label={t('register.password')}
              component={PasswordField}
              disabled={submitting}
              fullWidth autofocus required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name='passwordConfirmation'
              label={t('register.repeat-password')}
              component={PasswordField}
              disabled={submitting}
              fullWidth required
            />
          </Grid>

          {
            submitError &&
            <Grid item xs={12}>
              <Typography align='center' color='error'>
                {submitError}
              </Typography>
            </Grid>
          }
          <Grid item container justify='center'>
            <ProgressButton
              variant='contained'
              color='primary'
              type='submit'
              progress={submitting}
              disabled={submitting || hasValidationErrors}
            >
              {t('reset-password.submit')}
            </ProgressButton>
          </Grid>
      </Grid>
    </form>
  )
}

export default () => {
  const { t } = useTranslation();
  let code;
  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    code = urlParams.get('code');
  }

  return (
    <Layout
      pageName={t('page.reset-password')}
    >
      <>
        <Typography paragraph align='center' variant='h5'>
          {t('page.reset-password')}
        </Typography>
        {
          code
            ? <PasswordSubmissionForm code={code} />
            : <EmailSubmissionForm />
        }
      </>
    </Layout>
  )
}
